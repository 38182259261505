import { signInWithGoogle } from '../../services/firebase';
import { FcGoogle } from 'react-icons/fc'

export default function PageConnexion() {
    return (
        <div className='m-10 flex place-content-center'>
            <button className="flex rounded" onClick={signInWithGoogle}>
                <div className="flex-initial rounded-tl-lg rounded-bl-lg border-2 border-violet-kanoma p-2">
                    <FcGoogle size={48} />
                </div>
                <div className="flex-initial opacity-95 rounded-tr-lg rounded-br-lg border-2 border-violet-kanoma bg-violet-kanoma text-white p-5 px-3 hover:opacity-100"><b>Se connecter avec google</b></div>
            </button>
        </div>
    )
}