import './Footer.css';

function Footer(props) {
    return (
        <footer className='italic text-gray-500'>
            <p>© Kanoma 2022 - Tous droits r&eacute;serv&eacute;s - <span id="versionApp">{props.version}</span></p>
            <p>Dernière mise à jour : <span id="majDate">{props.majDate}</span></p>
        </footer>
    )
}

export default Footer