import AuthStatus from '../connexion/AuthStatus';
import './Header.css';
import { ReactComponent as LogoKanoma } from './images/logo.svg';
import { Menu } from './Menu';

function Header() {
    return (
        <header className="flex items-center p-2 h-15 flex flow-root">
            <div className='flex items-center float-left mr-2'>
                <LogoKanoma alt="Kanoma" className='w-[15%]' />
                <div className="kano-title cursor-default mx-2">Nuage de points</div>
                <Menu />
            </div>
            
            <div className="flex items-center float-right">
                <AuthStatus />
            </div>
        </header>
    )
}

export default Header;