import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "kanorh.firebaseapp.com",
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: "kanorh",
    storageBucket: "kanorh.appspot.com",
    messagingSenderId: "462302108252",
    appId: "1:462302108252:web:b4d0f4f20e7af3872ce499"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getDatabase(app);

auth.languageCode = 'fr'
const provider = new GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export const signInWithGoogle = () => signInWithPopup(auth, provider)
export const Logout = () => signOut(auth, provider)
