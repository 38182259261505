import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import NotFound from './composants/connexion/NotFound';
import NuagePoints from './composants/graphique/NuagePoints';
import { AuthProvider, RequireAuth } from './composants/connexion/Authentification';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <NuagePoints />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

