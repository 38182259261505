import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import React from 'react';
import { Bubble } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import './Graphique.css';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options = {
    plugins: {
        legend: {
            labels: {
                padding: 10,               
            }
        }
    },
    elements: {
        point: {
            radius: 10
        }
    },
    hoverRadius: 15,
    scales: {
        y: {
            title: {
                display: true,
                text: "Salaire annuel brut (incluant le variable théorique)",
            },
            display: true,
            suggestedMin: 25000,
            suggestedMax: 100000
        },
        x: {
            title: {
                display: true,
                text: "Années d'expérience",
            },
            display: true,
            beginAtZero: true
        }
    },
};

export default function Graphique(props) {
    const [dataGraph, setDataGraph] = useState({ datasets: [] });

    if(props.data && props.data.length > 0) {
        var maxSalaire = props.data.reduce((accumulator, current) => { 
            return accumulator.salaire_annuel > current.salaire_annuel ? accumulator : current;
        }).salaire_annuel;

        var minSalaire = props.data.reduce((accumulator, current) => { 
            return accumulator.salaire_annuel < current.salaire_annuel ? accumulator : current;
        }).salaire_annuel;

        options.scales.y.suggestedMax = Number(maxSalaire) + 5001
        options.scales.y.suggestedMin = Number(minSalaire) - 5001
    }

    useEffect(() => {
        const definirDatasets = (data, datasets) => {
            let newDatasets = datasets.filter(element => data.some(data => data.role.toLowerCase() === element.name.toLowerCase())).sort((a, b) => { return a.name.localeCompare(b.name) })
            return newDatasets;
        }

        const afficherPoints = (data, datasets) => {
            let dataGraph = { datasets: [] };
    
            let datasetsToUse = definirDatasets(data, datasets);

            dataGraph.datasets = datasetsToUse.map(role => {
                let dataKanomiens = data.filter(kanomien => { return kanomien.role.toLowerCase() === role.name.toLowerCase() })
                let newDataset = {
                    // On capitalize le label pour les rôles qui sont en minuscule. On le fait en js car chart.js ne permet pas de le gérer en css
                    label: role.name.slice(0, 1).toUpperCase() + role.name.slice(1),
                    data: dataKanomiens.map(kanomien => {
                        return {
                            x: parseFloat(kanomien.annees_experience),
                            y: kanomien.salaire_annuel,
                            size: dataKanomiens.length,
                        }
                    }),
                    backgroundColor: role.color
                }
                return newDataset;
            });
            setDataGraph(dataGraph);
        }

        if (props.data) {
            afficherPoints(props.data, props.datasets);
        }
    }, [props.data, props.datasets])

    return (
        <div id="graphContainer" >
            <Bubble options={options} data={dataGraph} />
        </div>
    )
}