import MenuItem from './MenuItem';
import { useState } from 'react';

import nantesIcon from './images/nantes_icon.png';
import rennesIcon from './images/rennes_icon.png';
import staffIcon from './images/staff_icon.png';

import { MenuContext } from './MenuContext';
import { useContext } from 'react';

function Menu() {
    const {tribuSelected, setTribuSelected} = useContext(MenuContext);
    const [menuNantesSelected, setMenuNantesSelected] = useState(tribuSelected==="nantes"?true:false);
    const [menuRennesSelected, setMenuRennesSelected] = useState(tribuSelected==="rennes"?true:false);
    const [menuStaffSelected, setMenuStaffSelected] = useState(tribuSelected==="staff"?true:false);
    const nantesActif = true;
    const rennesActif = true;
    const staffActif = true;

    function onClickNantes() {
        var selected = menuNantesSelected ? false : true
        setMenuNantesSelected(selected)
        setMenuRennesSelected(false)
        setMenuStaffSelected(false)
        setTribuSelected("nantes")
    }

    function onClickRennes() {
        var selected = menuRennesSelected ? false : true
        setMenuRennesSelected(selected)
        setMenuNantesSelected(false)
        setMenuStaffSelected(false)
        setTribuSelected("rennes")
    }

    function onClickStaff() {
        var selected = menuStaffSelected ? false : true
        setMenuStaffSelected(selected)
        setMenuRennesSelected(false)
        setMenuNantesSelected(false)
        setTribuSelected("staff")
    }

    return (
        <>
            {nantesActif &&
                <MenuItem label="Nantes" icon={nantesIcon} selected={menuNantesSelected} onClick={onClickNantes} />
            }
            {rennesActif &&
                <MenuItem label="Rennes" icon={rennesIcon} selected={menuRennesSelected} onClick={onClickRennes} />
            }
            {staffActif &&
                <MenuItem label="Staff" icon={staffIcon} selected={menuStaffSelected} onClick={onClickStaff} />
            }
        </>
    )
}

export {
    Menu
};