import { useAuth } from './Authentification';
import { auth } from '../../services/firebase';
import { FaPowerOff } from "react-icons/fa";

export default function AuthStatus() {
    let authent = useAuth();

    const onLogout = () => {
        auth.signOut()
    }

    if (authent.user) {
        return (
            <div>
                <div className='flex place-items-center'>
                    <span className='fontKanoma flex place-items-center h-8 text-white mr-2 h-[40px]' title={authent.user.email} >
                        {authent.user.displayName}<img className='rounded object-scale-down h-10 ml-2' alt={authent.user.displayName} src={authent.user.photoURL} />
                    </span>
                    <button className='bg-red-700 hover:bg-red-600 text-white font-bold py-2 px-4 rounded h-[40px]' title='Déconnexion' onClick={onLogout}><FaPowerOff /></button>
                </div>
            </div>
        )
    }
}