export default function MenuItem(props) {
    const selected = props.selected;

    return (
        <>
            {selected &&
                <div className="flex items-center float-left border-l-2 border-slate-300 " onClick={props.onClick}>
                    <div className='flex items-center mx-2 p-2 cursor-pointer bg-yellow-kanoma rounded' >
                        <img src={props.icon} alt="" className='w-4 h-4 mr-2' />
                        <div className="kano-title">{props.label}</div>
                    </div>
                </div>
            }
            {!selected &&
                <div className="flex items-center float-left border-l-2 border-slate-300 " onClick={props.onClick}>
                    <div className='flex items-center float-left mx-2 p-2 cursor-pointer hover:bg-violet-kanoma hover:rounded hover:text-slate-50' >
                        <img src={props.icon} alt="" className='w-4 h-4 mr-2' />
                        <div className="kano-title">{props.label}</div>
                    </div>
                </div>
            }
        </>

    )

}