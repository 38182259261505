import './App.css';
import Header from './composants/header/Header';
import Footer from './composants/footer/Footer';
import { Outlet } from 'react-router-dom';
import packageFile from '../package.json';
import { MenuContext } from './composants/header/MenuContext';
import { useState, useEffect } from "react";
import { db } from "./services/firebase";
import { onValue, ref, get } from "firebase/database";

function App() {
  const [tribuSelected, setTribuSelected] = useState("nantes");
  const [lastMajDate, setLastMajDate] = useState();
  const nomBDD = "meta_infos";

  useEffect(() => {
    const query = ref(db, nomBDD);
    get(query)
    return onValue(query, (snapshot) => {
        const rep = snapshot.val();

        setLastMajDate(rep.dateDerniereExecution)
    })
  })

  return (
    <div>
      <MenuContext.Provider value={{ tribuSelected, setTribuSelected }}>
        <Header />
        <Outlet />
        <Footer version={packageFile.version} majDate={lastMajDate} />
      </MenuContext.Provider>
    </div>
  );
}

export { App };
