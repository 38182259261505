import { useEffect, useState } from "react";
import { generateColor } from "../../services/utils";
import Filtre from "./Filtre";
import Graphique from "./Graphique";

import { db } from "../../services/firebase";
import { onValue, ref, get } from "firebase/database";
import { MenuContext } from '../header/MenuContext';
import { useContext } from 'react';

export default function NuagePoints(props) {
    const {tribuSelected} = useContext(MenuContext);
    const nomBDD = "salaires_"+tribuSelected;
    const [data, setData] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [filtre, setFiltre] = useState({ roles: [], tags: [] })
    const [roleByTag, setRoleByTag] = useState([])
    const [filtreSelectRoles, setFiltreSelectRoles] = useState([])
    const [filtreSelectTags, setFiltreSelectTags] = useState([])
    const [filtreSelectGenres, setFiltreSelectGenres] = useState([])

    useEffect(() => {
        const query = ref(db, nomBDD);
        get(query)
        return onValue(query, (snapshot) => {
            const rep = snapshot.val();

            // Roles
            let allRolesWithDoublon = rep.reduce((acc, kanomien) => acc.concat(kanomien.role), []);
            let allRoles = [...new Set(allRolesWithDoublon.map(element => element.toLowerCase()))];
            let datasets = allRoles.map(element => { return { name: element, color: generateColor() } });
            setDatasets(datasets)

            // Tags
            let allTagsWithDoublon = rep.reduce((acc, kanomien) => acc.concat(kanomien.tags), []);
            let allTags = [...new Set(allTagsWithDoublon.map(element => element.toLowerCase()))];

            let allGenresWithDoublon = rep.reduce((acc, kanomien) => acc.concat(kanomien.genre), []);
            let allGenres = [...new Set(allGenresWithDoublon.map(element => element.toLowerCase()))];

            setFiltre({ roles: allRoles, tags: allTags, genres: allGenres })
            setFiltreSelectGenres(allGenres)
            setFiltreSelectRoles(allRoles)
            setFiltreSelectTags(allTags)
            setRoleByTag(allRoles)

            setData(rep);
        })
    }, [nomBDD, tribuSelected])

    const onChangeCbxGenres = (filtreTmp) => {
        setFiltreSelectGenres(filtreTmp);
    }

    const onChangeCbxRoles = (filtreTmp) => {
        setFiltreSelectRoles(filtreTmp);
    }

    const onChangeCbxTags = (filtreTmp) => {
        setFiltreSelectTags(filtreTmp);
        // On réinitialise les rôles sélectionnés si les tags changent
        setFiltreSelectRoles(filtre.roles)
    }

    useEffect(() => {
        const filtreRolesByTag = () => {
            // On associe les rôles et les tags
            let tagsWithRoles = filtre.tags.map(tag => {
                let rolesByTag = data.filter(element => element.tags.includes(tag)).map(element => element.role.toLowerCase());
                let rolesByTagSansDoublon = [...new Set(rolesByTag.map(element => element.toLowerCase()))];
                return { name: tag, roles: rolesByTagSansDoublon };
            });
            // On filtre les tags ayant un rôle correspondant aux tags sélectionnés
            let tagsWithRolesSelect = tagsWithRoles.filter(tagRole => filtreSelectTags.some(tag => tag.toLowerCase() === tagRole.name.toLowerCase()));
            // On filtre la liste des rôles en fonction des tags filtrés
            let filteredRoles = filtre.roles.filter(role => tagsWithRolesSelect.some(tagRole => tagRole.roles.includes(role.toLowerCase())));
            return filteredRoles;
        }

        // Lors d'un changement sur les tags on recalcule la liste des rôles pour le filtre associé
        setRoleByTag(filtreRolesByTag());
    }, [filtreSelectTags, data, filtre])

    const filtreData = (data) => {
        let dataFiltreTag = data.filter(element => filtreSelectTags.some(tag => element.tags.map(tagElt => tagElt.toLowerCase()).includes(tag.toLowerCase())));
        let dataFiltreRole = dataFiltreTag.filter(element => filtreSelectRoles.map(role => role.toLowerCase()).includes(element.role.toLowerCase()));
        let dataFiltreGenre = dataFiltreRole.filter(element => filtreSelectGenres.includes(element.genre.toLowerCase()));
        return dataFiltreGenre;
    }

    return (
        <div>
            <div id="fitresWrapper">
                <Filtre data={filtre.tags} onChange={onChangeCbxTags} labelAll="All tags" titre="tags" />
                <Filtre data={roleByTag} onChange={onChangeCbxRoles} labelAll="All rôles" titre="rôles" />
                <Filtre data={filtre.genres} onChange={onChangeCbxGenres} labelAll="All genres" titre="genres" />
            </div>
            <Graphique data={filtreData(data)} datasets={datasets} />
        </div>
    )
}