import image404 from './404.jpg';

export default function NotFound() {
    return (
        <div className="m-10 flex items-center justify-center">
            <div>
                <img src={image404} alt="" />
            </div>
            <div className="text-center m-10">
                <h1 className="text-violet-kanoma text-lg font-bold">404 Page non trouvée</h1>
                <p>Ce n'est pas la page que vous recherchez.</p>
            </div>
        </div>
    )
}