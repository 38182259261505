import { useEffect, useState } from 'react';
import './Filtre.css';

import { FaAngleDown, FaAngleUp } from "react-icons/fa";

export default function Filtre(props) {
    const [dataFiltre, setDataFiltre] = useState([]);
    const [showFiltre, setShowFiltre] = useState(false);

    // FIXME : gérer le bug sur l'unicité du nom du filtre ALL
    const NOM_FILTRE_ALL = props.labelAll ? props.labelAll : "All";

    useEffect(() => {
        let dataFiltre = props.data?.map(role => { return { name: role, isChecked: true } })
        if (dataFiltre?.length > 0) {
            dataFiltre.sort((a, b) => { return a.name.localeCompare(b.name) });
            dataFiltre.unshift({ name: NOM_FILTRE_ALL, isChecked: true });
        }
        setDataFiltre(dataFiltre);
    }, [props.data, NOM_FILTRE_ALL])

    const onChange = (e) => {
        let elementSelect = e.target.value;
        let filtreTmp = [...dataFiltre];
        let element = filtreTmp.find(element => element.name === elementSelect);
        element.isChecked = !element.isChecked;

        if (elementSelect === NOM_FILTRE_ALL) {
            filtreTmp.forEach(role => role.isChecked = element.isChecked);
        }

        setDataFiltre(filtreTmp);
        props.onChange(filtreTmp.filter(filtre => filtre.isChecked).map(filtre => filtre.name));
    }

    const onShowHide = (e) => {
        setShowFiltre(!showFiltre)
    }

    return (
        <>
            {showFiltre && dataFiltre?.length > 0 && <div className='flex cursor-pointer text-xs border-y-2 border-y-slate-200 p-1' onClick={(e) => onShowHide(e)}><FaAngleUp /><span className='text-xs italic'>Masquer les filtres {props.titre} ({dataFiltre.filter(elt => elt.isChecked).length}/{dataFiltre.length})</span></div>}
            {!showFiltre && dataFiltre?.length > 0 && <div className='flex cursor-pointer bg-violet-kanoma text-xs text-slate-50 border-y-2 border-y-slate-50 p-1' onClick={(e) => onShowHide(e)}><FaAngleDown /><span className='text-xs text-slate-50 italic'>Afficher les filtres {props.titre} ({dataFiltre.filter(elt => elt.isChecked).length}/{dataFiltre.length})</span></div>}
            {dataFiltre?.length > 0 && <div className='bg-gray-200'><div id="fitreWrapper" className='ml-2 bg-slate-50'>    
                {showFiltre && dataFiltre?.map(element => {
                    let classElement = "flex place-items-center text-xs tracking-wide font-semibold px-2 py-1 rounded-md ml-3";
                    let classColor = " bg-gray-200 text-gray-600";
                    if (element.isChecked) {
                        classColor = " bg-violet-kanoma text-white";
                    }
                    classElement += classColor;

                    return (
                        <div key={element.name} className={classElement}>
                            <input className="accent-slate-50" type="checkbox" id={element.name} value={element.name} name="roles" onChange={(e) => onChange(e)} checked={element.isChecked} />
                            <label className="ml-2" htmlFor={element.name}>
                                {element.name}
                            </label>
                        </div>
                    )
                }
                )}
            </div></div>}
        </>
    )
}