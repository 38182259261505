import { createContext, useContext } from "react";
import { auth } from '../../services/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import PageConnexion from '../connexion/PageConnexion';

let AuthContext = createContext();

function RequireAuth({ children }) {
  const [user] = useAuthState(auth);

  if (user) {
    return children;
  } 

  return <PageConnexion />
}

function AuthProvider({ children }) {
  const [user] = useAuthState(auth);

  let signin = (googleData, callback) => {
    callback();
  };

  let signout = (callback) => {
    auth.signOut()
    callback();
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

export {
  useAuth,
  AuthProvider,
  RequireAuth
}